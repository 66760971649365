/* Main App Container */
.main-app {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }
  
  /* Sidebar (Fixed Left) */
  .sidebar {
    width: 250px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  /* Content Wrapper */
  .content {
    flex-grow: 1;
    margin-left: 250px; /* Same as Sidebar Width */
    width: calc(100% - 250px);
  }
  
  /* Header (Fixed Top) */
  .header {
    position: fixed;
    top: 0;
    left: 250px;
    width: calc(100% - 250px);
    height: 60px;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 1000;
  }
  
  /* Main Content Scrollable */
  .main-content {
    margin-top: 60px;
    height: calc(100vh - 60px);
    overflow-y: auto;
  }
  