.add-product-container {
    padding: 20px;
    background-color: #f9f9f9;
}

.breadcrumb {
    font-size: 14px;
    color: gray;
}

.breadcrumb .current {
    font-weight: bold;
}

h2 {
    font-weight: bold;
}

.sub-heading {
    font-size: 14px;
    color: gray;
    margin-bottom: 20px;
}

.image-upload-box {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.image-upload-box .upload-icon {
    font-size: 24px;
    color: #777;
}

.image-upload-box .upload-link {
    color: blue;
    text-decoration: underline;
}

.image-upload-box input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.tag-badge {
    padding: 5px;
    font-size: 12px;
}

.action-buttons {
    margin-top: 20px;
}