.header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-size: 20px;
    font-weight: bold;
    color: navy;
}

.toggle-icon {
    font-size: 24px;
    cursor: pointer;
}

.search-box {
    position: relative;
    width: 250px;
}

.search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.search-box input {
    padding-left: 30px;
}

.user-icon {
    font-size: 22px;
}