/* Sidebar Container */
.sidebar {
  width: 260px;
  height: 100vh;
  background: #fff;
  padding: 0px;
  border-right: 1px solid #ddd;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
}

/* Sidebar Header */
.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}


.settings-icon {
  font-size: 14px;
  cursor: pointer;
}

/* Navigation Items */
.nav-main {
  font-size: 12px;
  color: #333;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: background 0.3s ease;
  border-radius: 6px;
}

.nav-main:hover {
  background: #f1f1f1;
}

.nav-main.active {
  color: #007bff;
  text-align: left;
  font-weight: bold;
}

/* Align Icons Properly */
.nav-main .icon {
  font-size: 18px;
  width: 25px;
  text-align: center;
  margin-right: 10px;
}

/* Badge Styling */
.badge {
  background: red;
  color: white;
  padding: 3px 8px;
  font-size: 12px;
  text-align: right;
  border-radius: 12px;
}

/* Chevron Icons */
.chevron {
  font-size: 12px;
  color: #777;
  margin-left: auto;
}

/* Submenu */
.submenu {
  padding-left: 35px;
}

.nav-sub {
  font-size: 14px;
  color: #555;
  padding: 8px 15px;
  display: block;
  text-decoration: none;
}

.nav-sub:hover {
  color: #007bff;
}

/* Sidebar Section Title */
.sidebar-section {
  font-size: 14px;
  color: #999;
  margin: 15px 10px;
  text-transform: uppercase;
}
