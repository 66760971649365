/* View Products Page */
.view-products {
    margin-top: 20px;
    background: white;
    border-radius: 10px;
}

/* Tabs */
.nav-tabs .nav-link {
    color: black;
}

.nav-tabs .nav-link.active {
    font-weight: bold;
    border-bottom: 2px solid blue;
}

/* Top Bar */
.top-bar {
    display: flex;
    gap: 10px;
    margin: 15px 0;
    align-items: left;
}
.top-bar-buttons {
    display: flex;
    gap: 10px;
    margin: 15px 0;
    align-items: right;
}
/* Search Box */
.search-box {
    width: 250px;
}

.search-box .form-control {
    border-left: none;
}

/* Filters */
.filter-dropdown {
    width: 150px;
}

/* Buttons */
button {
    display: flex;
    align-items: center;
    gap: 5px;
}

/* Product Table */
.product-table {
    margin-top: 10px;
}

.product-table th {
    background: #f1f1f1;
}

.product-table td {
    vertical-align: middle;
}

/* Product Info */
.product-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.product-img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

/* Tags */
.tag-badge {
    margin-right: 5px;
}

/* Actions */
.icon {
    cursor: pointer;
    margin-right: 10px;
}

.icon.favorite {
    color: orange;
}