/* Dropdown Container */
.user-dropdown {
    position: relative;
    margin-left: 15px;
}

/* Profile Button (Circular Avatar) */
.profile-btn {
    background: none;
    border: none;
    padding: 0;
}

.profile-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;
}

/* Dropdown Menu */
.dropdown-menu {
    width: 250px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Profile Info */
.profile-info {
    text-align: center;
    padding-bottom: 10px;
}

.profile-img-lg {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 8px;
}

.profile-info h6 {
    margin: 5px 0;
    font-size: 16px;
    font-weight: bold;
}

.profile-info p {
    margin: 0;
    font-size: 14px;
    color: gray;
}

/* Dropdown Items */
.dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    font-size: 14px;
    color: #333;
}

.dropdown-item .icon {
    margin-right: 10px;
    font-size: 16px;
}

/* Logout Button */
.dropdown-item.logout {
    color: red;
}

.dropdown-item:hover {
    background: #f1f1f1;
}