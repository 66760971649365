/* Login Page Container */
.login-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f9fa;
  }
  
  /* Row Styling */
  .login-row {
    width: 90%;
    max-width: 1200px;
    display: flex;
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  /* Left Side - Banner */
  .login-banner {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    text-align: center;
  }
  
  .banner-content h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .banner-content p {
    font-size: 18px;
    font-weight: bold;
  }
  
  .banner-content span {
    font-size: 14px;
    opacity: 0.8;
  }
  
  .navigation-dots {
    margin-top: 20px;
    display: flex;
    gap: 5px;
  }
  
  .navigation-dots span {
    width: 10px;
    height: 10px;
    background: #ffffff55;
    border-radius: 50%;
  }
  
  .navigation-dots .active-dot {
    background: #fff;
  }
  
  /* Right Side - Login Form */
  .login-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }
  
  .login-form {
    width: 100%;
    max-width: 400px;
  }
  
  .login-form h3 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .login-form p {
    font-size: 14px;
  }
  
  .login-form a {
    color: #5c4dc6;
    text-decoration: none;
    font-weight: bold;
  }
  
  .forgot-password {
    display: block;
    text-align: right;
    margin-bottom: 15px;
    font-size: 14px;
  }
  
  /* Sign In Button */
  .signin-btn {
    width: 100%;
    color: white;
    border-radius: 50px;
    padding: 12px;
    font-weight: bold;
    margin-top: 10px;
  }
  
  /* Separator */
  .separator {
    text-align: center;
    margin: 20px 0;
    font-size: 14px;
    font-weight: bold;
    color: #888;
  }
  
  /* Social Login Buttons */
  .social-btn {
    width: 100%;
    border-radius: 50px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .google-btn {
    background: #fff;
    border: 1px solid #ddd;
  }
  
  .twitter-btn {
    background: #fff;
    border: 1px solid #ddd;
  }
  
  /* Footer Links */
  .footer-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
    font-size: 12px;
  }
  